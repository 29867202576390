import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import { Amplify } from "aws-amplify";
import outputs from "./amplify_outputs.json";
import ReactGA from "react-ga4";

import Router from "./router";
import i18n from "./translation";

ReactGA.initialize("G-YVP93QMDLM");
Amplify.configure(outputs);

ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

const App = () => (
  <BrowserRouter>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </BrowserRouter>
);

ReactDOM.render(<App />, document.getElementById("root"));
